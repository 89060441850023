body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(17, 24, 39);
}

@font-face {
  font-family: 'Epilogue';
  src: url('./assets/fonts/Epilogue/Epilogue-Regular.woff2') format('woff2'),
  url('./assets/fonts/Epilogue/Epilogue-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Epilogue';
  src: url('./assets/fonts/Epilogue/Epilogue-Black.woff2') format('woff2'),
  url('./assets/fonts/Epilogue/Epilogue-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Epilogue';
  src: url('./assets/fonts/Epilogue/Epilogue_Medium.woff') format('woff');
  font-weight: medium;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Epilogue';
  src: url('./assets/fonts/Epilogue/Epilogue-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

.marquee-container {
  overflow-x: hidden !important;
  display: flex !important;
  flex-direction: row !important;
  position: relative;
  width: 100%;
}

.marquee {
  flex: 0 0 auto;
  min-width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  animation: marquee_left var(--duration) linear var(--delay) var(--iteration-count);
  animation-play-state: var(--play);
  animation-delay: var(--delay);
  animation-direction: var(--direction);
}


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Epilogue", sans-serif;
    background-color: white;
    /* color: white; */
    /* background-color: rgb(231 229 228); */
    /* background: rgb(0,142,52);
    background: -moz-linear-gradient(180deg, rgba(0,142,52,0.8141850490196079) 0%, rgba(31,29,179,0.7693671218487395) 33%, rgba(115,0,166,0.8113839285714286) 56%, rgba(211,1,16,0.7665660014005602) 75%);
    background: -webkit-linear-gradient(180deg, rgba(0,142,52,0.8141850490196079) 0%, rgba(31,29,179,0.7693671218487395) 33%, rgba(115,0,166,0.8113839285714286) 56%, rgba(211,1,16,0.7665660014005602) 75%);
    background: linear-gradient(180deg, rgba(0,142,52,0.8141850490196079) 0%, rgba(31,29,179,0.7693671218487395) 33%, rgba(115,0,166,0.8113839285714286) 56%, rgba(211,1,16,0.7665660014005602) 75%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#008e34",endColorstr="#d30110",GradientType=1); */
  }

  /* section {
    background-image: radial-gradient(circle farthest-corner at 100% 100%,#4838ff,#fff 40%);
  } */
}